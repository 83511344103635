import React from "react";
import { Helmet } from "react-helmet";
//import { Header } from "./partials/header";
import { Footer } from "./partials/footer";

export const Layout = ({ children, breadcrumbs, metaTagArray = [], jsonStringLD = '' }) => {
    return (
        <>
            <Helmet>
                {metaTagArray && metaTagArray.length < 1 && <meta name='description' property='og:description' content={`${breadcrumbs[breadcrumbs.length - 1]} page`} />}
                {metaTagArray && metaTagArray.length > 0 && metaTagArray.map((metaTagObj, index) => {
                    return (
                        <meta name={metaTagObj.name} content={metaTagObj.content} key={`meta${index}`} />
                    )
                })}

                {/*
                {breadcrumbs && breadcrumbs.length == 1 && breadcrumbs[0] == "Blog" && <meta property="og:image:width" content="1200" />}
                {breadcrumbs && breadcrumbs.length == 1 && breadcrumbs[0] == "Blog" && <meta property="og:image:height" content="630" />}
                {breadcrumbs && breadcrumbs.length == 1 && breadcrumbs[0] == "Blog" && <meta property="og:image" content="https://d3hmzfrmu7sb02.cloudfront.net/IE_sharecard.png" />}
                {breadcrumbs && breadcrumbs.length == 1 && breadcrumbs[0] == "Blog" && <meta property="twitter:image" content="https://d3hmzfrmu7sb02.cloudfront.net/IE_sharecard.png" />}
                {breadcrumbs && breadcrumbs.length == 1 && breadcrumbs[0] == "Blog" && <meta name="twitter:card" content="summary_large_image" />}

                {breadcrumbs && !breadcrumbs.includes("Blog") && <meta property="og:image:width" content="1200" />}
                {breadcrumbs && !breadcrumbs.includes("Blog") && <meta property="og:image:height" content="630" />}
                {breadcrumbs && !breadcrumbs.includes("Blog") && <meta property="og:image" content="https://d3hmzfrmu7sb02.cloudfront.net/IE_sharecard.png" />}
                {breadcrumbs && !breadcrumbs.includes("Blog") && <meta property="twitter:image" content="https://d3hmzfrmu7sb02.cloudfront.net/IE_sharecard.png" />}
                {breadcrumbs && !breadcrumbs.includes("Blog") && <meta name="twitter:card" content="summary_large_image" />}
                */}

                {jsonStringLD &&
                    <script type="application/ld+json">
                        {jsonStringLD}
                    </script>
                }
                
                <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
                <title>
                    {breadcrumbs ? ` ${breadcrumbs.join(" - ")} | ` : ``}
                    OnlineTropa
                </title>
            </Helmet>
            
            {/*
            <Header />
            */}
            
            <main>{children}</main>
            
            {/*
            <Footer />
            */}
        </>
    )
}